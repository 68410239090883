<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="error-template">
          <h1>
            Oops!
          </h1>
          <h2>
            404 Not Found
          </h2>
          <div class="error-details"></div>
          <h1 v-if="$isMobile()">Instale nosso APP</h1>
          <div
            class="error-actions"
            style="display:flex;height:100px;justify-content:center"
            v-if="$isMobile()"
          >
            <img
              src="https://img.ibxk.com.br/2019/05/27/27192924184314.jpg"
              alt=""
              v-if="android"
              @click="google"
            />

            <img
              v-if="ios"
              src="https://i.pinimg.com/564x/bc/f3/c1/bcf3c152d1bbc4d5e1bbd2b17500523e.jpg"
              alt=""
              @click="apple"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isAndroid, isIOS, osName } from "mobile-device-detect";
export default {
  data() {
    return {
      android: isAndroid,
      ios: isIOS,
      win: osName,
    };
  },
  methods: {
    google: () => {
      window.location =
        "https://play.google.com/store/apps/details?id=com.whatsapp&hl=pt-BR";
    },
    apple: () => {
      window.location =
        "https://apps.apple.com/br/app/whatsapp-messenger/id310633997";
    },
  },
};
</script>

<style>
.error-template {
  padding: 40px 15px;
  text-align: center;
}
.error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}
.error-actions .btn {
  margin-right: 10px;
}
</style>
