import axios from "axios";

let BASE_URL = "http://45.32.1.209:3339/spotlights";

export default {
  state: { spotlight: {} },
  mutations: {
    setSpotlight(state, spotlight) {
      state.spotlight = spotlight;
    },
  },
  actions: {
    async getSpotlight({ commit }, id) {
      const response = await axios.get(BASE_URL + id);

      commit("setSpotlight", response.data);
    },
  },
};
