import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueMobileDetection from "vue-mobile-detection";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  BootstrapVue,
  IconsPlugin,
  ImagePlugin,
  BootstrapVueIcons,
} from "bootstrap-vue";

Vue.use(BootstrapVueIcons);
Vue.use(BootstrapVue);
Vue.use(VueMobileDetection);
Vue.use(IconsPlugin);
Vue.use(ImagePlugin);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
