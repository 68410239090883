import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Product from "../components/Product.vue";
import Invite from "../components/Invite.vue";
import Event from "../components/Event";
import HomeAdmin from "../components/admin/Home.vue";
import Login from "../components/admin/login/FormLogin.vue";
import Spotlighsts from "../components/Spotlighst.vue";
import NotFound from "../components/NotFound.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/vue",
    name: "Home",
    component: Home,
  },
  {
    path: "/views/v1/ads/:id",
    name: "Produto",
    component: Product,
    props: true,
  },
  {
    path: "/views/v1/spotlighsts/:id",
    name: "Spotlighsts",
    component: Spotlighsts,
    props: true,
  },
  {
    path: "/views/v1/invites/:id",
    name: "Invite",
    component: Invite,
    props: true,
  },
  {
    path: "/views/v1/events/:id",
    name: "Event",
    component: Event,
    props: true,
  },
  {
    path: "/admin",
    name: "Admin",
    component: HomeAdmin,
  },
  {
    path: "/admin/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/notfound",
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
