import axios from "axios";
import router from "../router";
let BASE_URL = "http://192.168.0.141:3333/invites/";

export default {
  state: { invite: {} },
  mutations: {
    setInvite(state, invite) {
      state.invite = invite;
    },
  },
  actions: {
    async getInvite({ commit }, id) {
      const response = await axios.get(BASE_URL + id);
      if (!response.data) {
        return router.push("/NotFound");
      } else {
        commit("setInvite", response.data);
      }
    },
  },
};
