<template>
  <b-modal
    v-if="$isMobile()"
    id="bv-modal-example"
    hide-footer
    size="sm"
    title=""
    hide-backdrop
    no-close-on-backdrop
    no-enforce-focus
  >
    <div class="w-100" @click="urlFoto" style="display-flex ">
      <b-container class="bv-example-row">
        <b-row>
          <b-col cols="4" md="8" style="height:70px">
            <img
              v-if="android"
              src="https://img.ibxk.com.br/2019/05/27/27192924184314.jpg"
              alt=""
              class="icone"
            />

            <img
              v-if="ios"
              src="https://i.pinimg.com/564x/bc/f3/c1/bcf3c152d1bbc4d5e1bbd2b17500523e.jpg"
              alt=""
              class="icone"
            />
          </b-col>
          <b-col cols="6" md="4"
            ><div>
              <h4 class="negrito font14">kiteradar</h4>
              <p class="font12">Para melhor navageção Instale Nosso App</p>
            </div></b-col
          >
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>
<script>
import { isAndroid, isIOS } from "mobile-device-detect";
export default {
  data() {
    return {
      android: isAndroid,
      ios: isIOS,

      link: ""
    };
  },
  created() {},
  methods: {
    urlFoto: function() {
      if (this.android) {
        window.location =
          "https://play.google.com/store/apps/details?id=com.whatsapp&hl=pt-BR";
      }
      if (this.ios) {
        window.location =
          "https://apps.apple.com/br/app/whatsapp-messenger/id310633997";
      }
    }
  }
};
</script>
<style scoped>
.icone {
  width: auto;
  height: auto;
  max-height: 100%;

  border-radius: 2px;
}
</style>
<style>
.modal-header {
  border-bottom: none !important;
  padding: 0rem !important;
}
.modal-content {
  opacity: 0.9 !important;
}
.modal-dialog {
  margin: 1.5rem !important;
}
.modal-body {
  padding: 0 !important;
}

.modal-header .close {
  margin: -1rem -0.6rem -1rem auto !important;
}
</style>
