import Vue from "vue";
import Vuex from "vuex";
import Product from "./product";
import Invite from "./invite";
import Spotlighst from "./spotlighst";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { Product, Invite, Spotlighst }
});
