import axios from "axios";
import router from "../router";

let BASE_URL = "http://192.168.0.141:3333/adv/list?id=";

export default {
  state: { product: {} },
  mutations: {
    setProduct(state, product) {
      state.product = product;
    },
  },
  actions: {
    async getProduct({ commit }, id) {
      const response = await axios.get(BASE_URL + id);
      if (!response.data) {
        return router.push("/NotFound");
      } else {
        commit("setProduct", response.data);
      }
    },
  },
};
