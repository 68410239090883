<template>
  <div>
    <spinner v-if="spinner"> </spinner>
    <b-navbar toggleable type="dark" variant="primary">
      <b-navbar-brand href="# ">Kiteradar - Destaques</b-navbar-brand>
    </b-navbar>
    <b-container fluid>
      <b-row>
        <b-col
          sm="3"
          class=""
          style="padding-right: 0px; 
    padding-left: 0px;"
        >
          <b-card class="borderNone">
            <b-container style="">
              <b-row class="">
                <b-col cols="8" style="padding-top:10px ">
                  <b-row class="title"
                    ><span class=" font18 negrito  ">Sunsetem SMG</span>
                  </b-row>
                  <p class="cinza font8  ">
                    Publicado em 11/8 as 18h
                  </p>
                </b-col>
                <b-col cols="4"
                  ><div style="display:flex; padding-top:10px;  ">
                    <span
                      class="cinza font8 "
                      style="padding-top:20px;padding-right:2px"
                      >Delano lopes</span
                    >
                    <div style="padding-top:10px">
                      <img
                        src="https://image.flaticon.com/icons/png/512/64/64572.png"
                        style=" border-radius:100%;height:30px;width:30px, object-fit:cover;  
                  "
                      />
                    </div></div
                ></b-col>
              </b-row>
            </b-container>
            <b-carousel
              id="carousel-1"
              :interval="999999"
              indicators
              dialog-class
              controls
              background="#ababab"
              img-width="1024"
              img-height="480"
              style="text-shadow: 1px 1px 2px #333;top:-10px"
              class="imagen"
            >
              <div v-for="foto in dados.photos" :key="foto.id">
                <b-carousel-slide class="imagen" :img-src="foto.file.url">
                </b-carousel-slide>
              </div>
            </b-carousel>
            <b-card-text
              class="preco"
              style="display:flex; justify-content:space-between;"
            >
              <font-awesome-icon v-b-modal.modal-center icon="comments" />
              <font-awesome-icon icon="thumbs-up" />

              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div>
                <font-awesome-icon icon="wind" />
                <span style="padding-left:1px">18kn ESE</span>
              </div>
              <div></div>
              <div></div>
              <div></div>
            </b-card-text>
            <b-card-text class="preco font12 "
              >Rodizio de Pizza e Chrurasco Hoje, dia 26, R$40,00 Por
              pessoa..... <span class="negrito"> leia mais.</span></b-card-text
            >

            <div style="display:flex; justify-content:center;font-size:8px">
              <p class="cinza">
                Curtido por Fulano,Beltrano e por mais 4 Pessoas
              </p>
            </div>
          </b-card>
        </b-col>
        <b-col class="preco"> </b-col>
      </b-row>
    </b-container>

    <modal />
  </div>
</template>

<script>
import moment from "moment";
import modal from "./CustomModal";
import spinner from "./Spinner";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faThumbsUp,
  faComments,
  faWind,
} from "@fortawesome/free-solid-svg-icons";

library.add(faThumbsUp, faComments, faWind);
moment.locale("pt-br");
export default {
  data: function() {
    return {
      spinner: true,
      fotos: [],
    };
  },
  beforeCreate() {
    this.$store.dispatch("getSpotlight", this.$route.params.id);
    setTimeout(() => {
      this.$bvModal.show("bv-modal-example");
    }, 1000);
  },
  created() {
    setTimeout(() => {
      this.spinner = false;
    }, 1000);
    this.moment = moment;
  },

  computed: {
    dados: function() {
      return this.$store.state.Spotlighst.spotlight;
    },
  },
  components: {
    modal,
    spinner,
  },
};
</script>

<style scoped>
.space-between {
  justify-content: space-between;
}
.description {
  text-align: initial;
}
.description-title {
  color: black;
}
.color-title {
  color: #007bff;
}
.stolen-footer {
  display: flex;
  justify-content: space-between;
}
.align-center {
  text-align: center;
}

.spinner {
  position: fixed;
  right: 45%;
  top: 50%;
  z-index: 1;
}
.anunciante {
  background-color: #f5f5f5;
  border-radius: 5px;
}

.link {
  color: #007bff;
}

.cinza {
  color: #858585;
}
.borderNone {
  border: none;
}
.card-header {
  border-bottom: none;
}
.imagen {
  height: 300px;
  width: 100%;
  object-fit: cover;
}
.anunciante-info {
  margin-bottom: -0.38rem;
}

@media only screen and (max-width: 768px) {
  div.card-body {
    padding: 0;
  }
}
</style>

<style>
.img-fluid {
  max-width: 100%;
  height: 300px !important;
  object-fit: cover;
}
.negrito {
  font-weight: bolder;
}
.font12 {
  font-size: 12px;
}
.font20 {
  font-size: 20px;
}
.font14 {
  font-size: 14px;
}
.font24 {
  font-size: 24px;
}
.font8 {
  font-size: 8px;
}
.col {
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.title {
  padding-right: 13px !important;
  padding-left: 13px !important;
  padding-top: 0px !important;
}
.preco {
  padding-right: 13px !important;
  padding-left: 13px !important;
  padding-top: 8px !important;
}
</style>

<style scoped>
.space-between {
  justify-content: space-between;
}
.description {
  text-align: initial;
}
.description-title {
  color: black;
}
.color-title {
  color: #007bff;
}
.stolen-footer {
  display: flex;
  justify-content: space-between;
}
.align-center {
  text-align: center;
}

.spinner {
  position: fixed;
  right: 45%;
  top: 50%;
  z-index: 1;
}
.anunciante {
  background-color: #f5f5f5;
  border-radius: 5px;
}

.link {
  color: #007bff;
}

.cinza {
  color: #858585;
}
.borderNone {
  border: none;
}
.card-header {
  border-bottom: none;
}
.imagen {
  height: 300px;
  width: 100%;
  object-fit: cover;
}
.anunciante-info {
  margin-bottom: -0.38rem;
}

@media only screen and (max-width: 768px) {
  div.card-body {
    padding: 0px;
  }
}
</style>
