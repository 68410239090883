<template>
  <div>
    <spinner v-if="spinner"></spinner>
    <b-navbar toggleable type="dark" variant="primary">
      <b-navbar-brand href="# ">Kiteradar - Ocorrência</b-navbar-brand>
    </b-navbar>
    <b-container fluid>
      <b-row>
        <b-col
          sm="3"
          class=""
          style="padding-right: 0px; 
    padding-left: 0px;"
        >
          <b-card class="borderNone">
            <b-carousel
              id="carousel-1"
              :interval="999999"
              indicators
              dialog-class
              controls
              background="#ababab"
              img-width="1024"
              img-height="480"
              style="text-shadow: 1px 1px 2px #333;"
              class="imagen"
            >
              <div v-for="foto in dados.AdPhotos" :key="foto.id">
                <b-carousel-slide class="imagen" :img-src="foto.photo.url">
                </b-carousel-slide>
              </div>
            </b-carousel>
            <b-card-text class="preco" style="padding-r">
              <div style="padding-bottom:10px">
                <b-button
                  disabled
                  style="border-radius:5px"
                  block
                  variant="warning"
                >
                  <span style=" color:#FFFFFF " class="font24">Perdido</span>
                </b-button>
              </div>

              <span class="cinza font20 ">{{ dados.title }}</span>

              <p class="cinza font14">
                Publicado em {{ moment(dados.createdAt).format("DD/MM") }} as
                {{ moment(dados.createdAt).format("h:mm") }}
              </p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col>
          <b-card
            class=" color-title card-header p-1 "
            style="
                background: none;
                border:none"
          >
            <b-card-text v-if="dados.description" class="">
              <div class="description-title  ">
                <div class="font14">
                  <span class="color-title negrito ">Local da ocorrência </span>
                </div>
                <div class="mt-1 cinza font14">
                  <p v-if="dados.description">{{ dados.description }}</p>
                </div>
                <div class="font14">
                  <span class="color-title negrito "
                    >Descrição da ocorrência
                  </span>
                </div>
                <div class="mt-1 cinza font14">
                  <p v-if="dados.description">{{ dados.description }}</p>
                </div>

                <div class="mt-1  font14 ">
                  <span class="color-title negrito ">Ficha Técnica </span>
                </div>
                <div class="mt-1 font14">
                  <span class="description-title cinza negrito "
                    >Categoria:
                  </span>
                  <span class="cinza font14" v-if="dados.category.description">
                    {{ dados.category.description }}
                  </span>
                </div>
                <div class="mt-1 font14">
                  <span class="description-title cinza negrito">Ano: </span>
                  <span class="cinza" v-if="dados.year">{{ dados.year }}</span>
                </div>
                <div class="mt-1 font14">
                  <span class="description-title cinza negrito">Marca: </span>
                  <span class="cinza" v-if="dados.brand.description">{{
                    dados.brand.description
                  }}</span>
                </div>
                <div class="mt-1 font14">
                  <span class="description-title cinza negrito">Modelo: </span>
                  <span v-if="dados.year" class="cinza">{{ dados.year }}</span>
                </div>
                <div class="mt-1 font14">
                  <span class="description-title cinza negrito"
                    >Condição:
                  </span>
                  <span v-if="dados.year" class="cinza">{{ dados.year }}</span>
                </div>

                <b-card class="anunciante cinza  mt-3 p-2">
                  <div>
                    <div class="">
                      <h6 class="description-title 	">Anunciante:</h6>
                      <p class="anunciante-info font14" v-if="dados.user.name">
                        {{ dados.user.name }} {{ dados.user.last_name }}
                      </p>
                    </div>

                    <div class="font14">
                      <p class="anunciante-info" v-if="dados.city.name">
                        {{ dados.city.name }}, {{ dados.state.name }}
                      </p>
                    </div>
                    <div class="anunciante-info font14">
                      <span class="description-title cinza anunciante-info"
                        >CEP:
                      </span>
                      <span class="anunciante-info" v-if="dados.user.cep">{{
                        dados.user.cep
                      }}</span>
                    </div>
                    <div class="mt-1  anunciante-info font12 ">
                      <span class="description-title cinza "
                        >No kiteradar desde
                      </span>
                      <span v-if="dados.user.cep">
                        {{ moment(dados.user.createdAt).format("LL") }}
                      </span>
                    </div>
                  </div>
                </b-card>

                <div class="text-center mt-2 font14">
                  <span class="cinza">Irregularidades? </span>
                  <a class="link negrito">Denunciar </a>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <modal />
  </div>
</template>

<script>
import moment from "moment";
import modal from "./CustomModal";
import spinner from "./Spinner";
moment.locale("pt-br");
export default {
  data: function() {
    return {
      spinner: true,
      fotos: [],
    };
  },
  beforeCreate() {
    this.$store.dispatch("getProduct", this.$route.params.id);
    setTimeout(() => {
      this.$bvModal.show("bv-modal-example");
    }, 1000);
  },
  created() {
    setTimeout(() => {
      this.spinner = false;
    }, 1000);
    this.moment = moment;
  },

  computed: {
    dados: function() {
      return this.$store.state.Product.product;
    },
  },
  components: {
    modal,
    spinner,
  },
};
</script>

<style scoped>
.space-between {
  justify-content: space-between;
}
.description {
  text-align: initial;
}
.description-title {
  color: black;
}
.color-title {
  color: #007bff;
}
.stolen-footer {
  display: flex;
  justify-content: space-between;
}
.align-center {
  text-align: center;
}

.spinner {
  position: fixed;
  right: 45%;
  top: 50%;
  z-index: 1;
}
.anunciante {
  background-color: #f5f5f5;
  border-radius: 5px;
}

.link {
  color: #007bff;
}

.cinza {
  color: #858585;
}
.borderNone {
  border: none;
}
.card-header {
  border-bottom: none;
}
.imagen {
  height: 300px;
  width: 100%;
  object-fit: cover;
}
.anunciante-info {
  margin-bottom: -0.38rem;
}

@media only screen and (max-width: 768px) {
  div.card-body {
    padding: 0;
  }
}
</style>

<style>
.img-fluid {
  max-width: 100%;
  height: 300px !important;
  object-fit: cover;
}
.negrito {
  font-weight: bolder;
}
.font12 {
  font-size: 12px;
}
.font20 {
  font-size: 20px;
}
.font14 {
  font-size: 14px;
}
.font24 {
  font-size: 24px;
}
.col {
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.preco {
  padding-right: 13px !important;
  padding-left: 13px !important;
  padding-top: 8px;
}
</style>

<style scoped>
.space-between {
  justify-content: space-between;
}
.description {
  text-align: initial;
}
.description-title {
  color: black;
}
.color-title {
  color: #007bff;
}
.stolen-footer {
  display: flex;
  justify-content: space-between;
}
.align-center {
  text-align: center;
}

.spinner {
  position: fixed;
  right: 45%;
  top: 50%;
  z-index: 1;
}
.anunciante {
  background-color: #f5f5f5;
  border-radius: 5px;
}

.link {
  color: #007bff;
}

.cinza {
  color: #858585;
}
.borderNone {
  border: none;
}
.card-header {
  border-bottom: none;
}
.imagen {
  height: 300px;
  width: 100%;
  object-fit: cover;
}
.anunciante-info {
  margin-bottom: -0.38rem;
}

@media only screen and (max-width: 768px) {
  div.card-body {
    padding: 0;
  }
}
</style>
