<template>
  <div>
    <Navbar></Navbar>
    <b-container>
      <b-row align-h="center"> </b-row>
    </b-container>
  </div>
</template>

<script>
import Navbar from "./navbar/Navbar";
export default {
  data() {
    return {
      transProps: {
        // Transition name
        name: "flip-list"
      },
      items: [
        { ID: 2, Categoria: "Two", Valor: "Moose" },
        { ID: 1, Categoria: "Three", Valor: "Dog" },
        { ID: 3, Categoria: "Four", Valor: "Cat" },
        { ID: 4, Categoria: "One", Valor: "Mouse" }
      ],
      fields: [
        { key: "ID", sortable: true },
        { key: "Categoria", sortable: true },
        { key: "Valor", sortable: true }
      ]
    };
  },
  components: {
    Navbar
  }
};
</script>

<style>
table#table-transition-example .flip-list-move {
  transition: transform 1s;
}
</style>
